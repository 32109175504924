import React from "react";
import Template from '../components/Template/Template';
import Faq from '../sections/Faq/Faq';

const Marki = () => {

    return (
        <Template path="/marka-minolta" click={() => alert("Click")}>
           
           <h1 className="ch1">Minolta - skup tonerów</h1>
            <section id="pSell" className="ccon">
                <p>Firma ta oferuje szeroki wybór produktów &ndash; drukarki Minolta służą między innymi do przygotowywania recept! Znaleźć je można także w firmach, zakładach produkcyjnych, biurach, jak również w domach prywatnych. Jeśli w miejscach tych często robi się wydruki, wkłady schodzą na bieżąco. Jednak jeśli z produktu nie korzysta się często, może dojść do sytuacji, w której nagle znajdujemy oryginalny, pełny toner Minolta, z którym nie wiemy, co zrobić.</p>
                <p>Sposobem, by odzyskać część pieniędzy, jakie na niego wydaliśmy, jest skorzystanie z usług skupu. Wystarczy, że zgłoszą się Państwo do nas: <a href="/#pSell">wycenimy</a> Wasz egzemplarz, a potem odbierzemy go od Was i przekażemy pieniądze. Skup oryginalnych tonerów znajduje się w Warszawie, w mieście tym prowadzimy odbiór sami. Natomiast do takich miast jak Koszalin, Ruda Śląska, Szczecin czy inne wysyłamy kuriera.</p>
            </section>
    

        </Template>
    )
}

export default Marki
